 <template>
  <b-card title="Contact us">
    <b-form>
      <b-row>
        <!-- first name -->
        <b-col cols="12">
          <b-form-group label="Names" label-for="v-name">
            <b-form-input v-model="name" id="v-name" placeholder="Name" />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group label="Company" label-for="v-company">
            <b-form-input v-model="company" id="v-company" placeholder="Company" />
          </b-form-group>
        </b-col>

        <!-- email -->
        <b-col cols="12">
          <b-form-group label="Email" label-for="v-email">
            <b-form-input
              v-model="email"
              id="v-email"
              type="email"
              placeholder="Email"
            />
          </b-form-group>
        </b-col>

        <!-- subject -->
        <b-col cols="12">
          <b-form-group label="Subject" label-for="v-subject">
            <b-form-input
              v-model="subject"
              id="v-subject"
              type="text"
              placeholder="Subject"
            />
          </b-form-group>
        </b-col>

        <!-- about -->
        <b-col cols="12">
          <b-form-group label="Description" label-for="v-description">
            <b-form-textarea
              id="v-description"
              placeholder="Enter your message..."
              rows="3"
              max-rows="6"
            ></b-form-textarea>
          </b-form-group>
        </b-col>

        <!-- submit and reset -->
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            @click="onSubmit"
            variant="primary"
            class="mr-1"
          >
            <div v-if="isLoading" class="loader">Submitting...</div>
            <div v-else>Submit</div>
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormCheckbox,
  BForm,
  BButton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { sendEnquiry } from "@/aws/Api";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormTextarea,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      name: "",
      email: "",
      subject: "",
      message: "",
      company: "",
      isLoading: false,
    };
  },
  methods: {
    async onSubmit() {
      try {
        this.isLoading = true;
        const params = {
          name: this.name,
          subject: this.subject,
          email: this.email,
          company: this.company,
          message: this.message,
        };
        // send enquiry
        await sendEnquiry(params);

        this.name = "";
        this.email = "";
        this.subject = "";
        this.message = "";
        this.company = ""

        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Automatum Enquiry`,
            icon: "CoffeeIcon",
            variant: "success",
            text: `We have received your enquiry and will get back to you as soon as possible`,
          },
        });
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Automatum`,
            icon: "CoffeeIcon",
            variant: "error",
            text: `An error occurred while sending your enquiry! ${error}`,
          },
        });
      }
    },
  },
};
</script>

<style lang="css" scoped>
.loader {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
</style>
 